import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {withRouter} from 'react-router-dom';

import './style.scss';

class Menu extends Component {
 
  static propTypes = {
    menu: PropTypes.array.isRequired,
    config:PropTypes.object.isRequired
  };

  state = {
    isOpen: false,
    subMenu:[],
    selectedMenu:'',
    selectedID:''
  };

  componentWillReceiveProps(nextProps) {
  }

  openMenu = () => {
    this.setState({ isOpen: true });
  };

  closeMenu = () => {
    this.setState({ isOpen: false });
  };

  onMenuItemSelected = (name, id, type)=>{
    
    if(type==='category')
    {
      //check if this has sun catgories 
      let subMenu = this.props.menu.filter(f=> f.ParentID === id)
      if(subMenu.length === 0 )
      {
        //show products beloging to this category
        this.props.history.push('/' + this.props.config.BusinessUniqueName + '/l1/'+ name);
        this.closeMenu();
      }
      else
      {
        //show sub categories      
        this.setState({subMenu:subMenu,selectedMenu:name, selectedID:id });
      }
    }
    else if(type==='back')
    {
      this.setState({subMenu:[],selectedMenu:'', selectedID:'' });
    }
    else if(type==="subcategory")
    {
      // history1.push('/' + this.props.config.BusinessUniqueName + '/l1/'+ name);
      this.props.history.push('/' + this.props.config.BusinessUniqueName + '/l2/'+ name);
      this.closeMenu();
    }
  }

  render() {
    return (<React.Fragment>
      <div className="menubar">
        <div className="menubar-button pull-left">
            <svg  onClick={()=> this.openMenu()} xmlns="http://www.w3.org/2000/svg" fill="black" viewBox="0 0 20 20" width="30px" height="30px"><path d="M 3 5 A 1.0001 1.0001 0 1 0 3 7 L 21 7 A 1.0001 1.0001 0 1 0 21 5 L 3 5 z M 3 11 A 1.0001 1.0001 0 1 0 3 13 L 21 13 A 1.0001 1.0001 0 1 0 21 11 L 3 11 z M 3 17 A 1.0001 1.0001 0 1 0 3 19 L 21 19 A 1.0001 1.0001 0 1 0 21 17 L 3 17 z"/></svg>
        </div>
        <div className="businessname">
            {this.props.config.BusinessName}
        </div>
    </div>
    <div className={this.state.isOpen ? "menu open": "menu" }>
      {this.state.isOpen && (
          <div onClick={() => this.closeMenu()} className="seperator">
            <div className="menu-close-btn">
              <svg xmlns="http://www.w3.org/2000/svg" fill="#101928" viewBox="0 0 6 11" className="menu-back-button" width="12px" height="24px"><path d="M1.207 5.5l4.647 4.646a.5006.5006 0 1 1-.708.708l-5-5a.5.5 0 0 1 0-.708l5-5a.5006.5006 0 1 1 .708.708L1.207 5.5z"></path></svg>
              <div>BACK</div>
            </div>
           
          </div>
      )}
      <div className="menu-content">
        <div className="menu-button-container">
          {this.state.selectedID ==="" && <React.Fragment>
              {this.props.menu.filter(f=> f.ParentID ==="").map(m=> <div 
              key={"category_" + m.ID}
              className="menu-row seperator" 
              onClick={()=> this.onMenuItemSelected(m.Name,m.ID, 'category')}
              >
                          <div className="menu-row-text">{m.Name}</div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 11" fill="#000000" width="12px" height="12px"><path d="M.5 11c-.1 0-.3 0-.4-.1-.2-.2-.2-.5 0-.7l4.6-4.6L.1.9C0 .7 0 .3.1.1s.5-.2.7 0l5 5c.2.2.2.6.1.8l-5 5c-.1.1-.3.1-.4.1z"></path></svg>
              </div>)}
          </React.Fragment>}
          {this.state.selectedID !=="" && <React.Fragment>
              <div className="menu-row seperator" onClick={()=> this.onMenuItemSelected('','','back')}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="#101928" viewBox="0 0 6 11" className="menu-back-button" width="12px" height="12px"><path d="M1.207 5.5l4.647 4.646a.5006.5006 0 1 1-.708.708l-5-5a.5.5 0 0 1 0-.708l5-5a.5006.5006 0 1 1 .708.708L1.207 5.5z"></path></svg>
                  <div className="menu-text-header">{this.state.selectedMenu}</div>
              </div>
              {this.props.menu.filter(f=> f.ParentID === this.state.selectedID).map(m=> <div
               key={"subcategory_" + m.ID} 
              className="menu-row seperator" 
              onClick={()=> this.onMenuItemSelected(m.Name,m.ID, 'subcategory')}
              >
                          <div className="menu-row-text">{m.Name}</div>
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 6 11" fill="#000000" width="12px" height="12px"><path d="M.5 11c-.1 0-.3 0-.4-.1-.2-.2-.2-.5 0-.7l4.6-4.6L.1.9C0 .7 0 .3.1.1s.5-.2.7 0l5 5c.2.2.2.6.1.8l-5 5c-.1.1-.3.1-.4.1z"></path></svg>
              </div>)}
          </React.Fragment>}
        </div>
        <div className="menu-footer">
        </div>
      </div>
    </div>
   </React.Fragment> );
  }
}

const mapStateToProps = state => ({
  menu:state.api.menu,
  config:state.api.config
});

export default withRouter(connect(
  mapStateToProps,
  { }
)(Menu));
