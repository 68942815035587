import React from 'react';

const PageFooter = ({config})=>{
    return <div className="page-footer">
         <br/>
        <div>Contact us at: {config.ContactNo}</div>
        <div>Email us at: {config.EmailID}</div>
        <div>Powered by <a href="https://posible.in">Posible</a></div>
        <br/>
        <br/>
        <br/>
    </div>
}

export default PageFooter;