import React, { Component } from 'react';
import PropTypes from 'prop-types';


class CartProduct extends Component {
  static propTypes = {
    lineItem: PropTypes.object.isRequired,
    removeProduct: PropTypes.func.isRequired,
    changeProductQuantity: PropTypes.func.isRequired,
  };

  constructor(props) {
    super(props);
    this.state = {
      lineItem: this.props.lineItem,
      isMouseOver: false
    };
  }

  handleMouseOver = () => {
    this.setState({ isMouseOver: true });
  };

  handleMouseOut = () => {
    this.setState({ isMouseOver: false });
  };

  handleOnIncrease = () => {
    const { changeProductQuantity } = this.props;
    const { lineItem } = this.state;
    lineItem.Qty = Number(lineItem.Qty) + 1;
    changeProductQuantity(lineItem);
  }

  handleOnDecrease = () => {
    const { changeProductQuantity } = this.props;
    const { lineItem } = this.state;
    lineItem.Qty = lineItem.Qty - 1;
    changeProductQuantity(lineItem);
  }

  render() {
    const { removeProduct } = this.props;
    const { lineItem } = this.state;

    const classes = ['shelf-item'];

    if (!!this.state.isMouseOver) {
      classes.push('shelf-item--mouseover');
    }

    const getThumbNailImage= (m, className)=>{
      let url = ''
      // console.log(m)
      if(m.Images && m.Images.length > 0)
      {
        if(m.Images[0])
        {
            if(m.Images[0].ImagePath !=="")
            {
                url = m.Images[0].ImagePath
            }
        }
      }
  
      if(url !=="")
      {
          return <img src={url} className={className} alt="..."/>
          // "card-img-top menu-thumbnail-image"
      }
      else
      {
        return  <svg className="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img">
        <rect width="100%" height="100%" fill="#777"></rect>
        <text x="40%" y="50%" fill="#eceeef" style={{fontSize:"50px"}}>{m.Name.substring(0, 1)}</text>
        </svg>
      }
    };

    return (
      <div className={classes.join(' ')}>
        {/* <div
          className="shelf-item__del"
          onMouseOver={() => this.handleMouseOver()}
          onMouseOut={() => this.handleMouseOut()}
          onClick={() => removeProduct(lineItem)}
        /> */}
        {/* <Thumb
          classes="shelf-item__thumb"
          // src={require(`../../../static/products/${product.sku}_2.jpg`)}
          alt={lineItem.Product.Name}
        /> */}
        <div className="shelf-item__thumb">
            {getThumbNailImage(lineItem.Product,"card-img-top")}
        </div>
        
        <div className="shelf-item__details">
          <p className="title">{lineItem.Product.Name}</p>
          <p className="desc">
            {/* {lineItem.Product.SKU}<br /> */}
            <div className="btn-group">
              <button type="button" className="btn btn-default" onClick={this.handleOnDecrease} disabled={lineItem.Qty === 0 ? true : false} > - </button>
              <input type="text" className="form-control" value={lineItem.Qty} style={{width:"50px"}}/>
              <button type="button" className="btn btn-default" onClick={this.handleOnIncrease} > + </button>
            </div>
            {/* Qty: {lineItem.Qty} */}
          </p>
        </div>
        <div className="shelf-item__price">
          <p>{lineItem.Amount}</p>
          {/* <div>
            <button onClick={this.handleOnDecrease} disabled={lineItem.Qty === 1 ? true : false} className="change-product-button">-</button>
            <button onClick={this.handleOnIncrease} className="change-product-button">+</button>
          </div> */}
        </div>
      </div>
    );
  }
}

export default CartProduct;
