import { FETCH_MENU,FETCH_CONFIG, FETCH_PRODUCT_TAXES} from './actionTypes';
import axios from 'axios';
import {menuAPI , configAPI, productTaxesAPI} from '../util';

export const fetchMenu = (businessUniqueName) => dispatch => {
  return axios
    .post(menuAPI, { from: 0, size: 1000, uniqueName: businessUniqueName })
    .then(res => {
      let { menu } = res.data;
      // console.log('fetchCategories', menu)
      return dispatch({
        type: FETCH_MENU,
        payload: menu
      });
    })
    .catch(err => {
      console.log('Could not fetch categories. Try again later.');
    });
};

export const fetchConfig = (businessUniqueName) => dispatch => {
  return axios
    .post(configAPI, {uniqueName: businessUniqueName })
    .then(res => {
      let { config } = res.data;
      return dispatch({
        type: FETCH_CONFIG,
        payload: config
      });
    })
    .catch(err => {
      console.log('Could not fetch config. Try again later.');
    });
};

export const fetchProductTaxes = (key) => dispatch => {
  return axios
    .post(productTaxesAPI, {RegistrationID: key })
    .then(res => {
      let { productTaxes } = res.data;
      return dispatch({
        type: FETCH_PRODUCT_TAXES,
        payload: productTaxes
      });
    })
    .catch(err => {
      console.log('Could not fetch product taxes. Try again later.');
    });
};


