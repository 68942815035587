
import React ,{Component} from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {fetchMenu} from '../../services/api/actions';
import { fetchProducts } from '../../services/util';
import Spinner from '../Spinner';
import MenuThumbNails from '../MenuThumbNails';
import ProductList from '../ProductList';
import PageFooter from '../PageFooter';

class Level1 extends Component {

    static propTypes = {
        menu: PropTypes.array.isRequired,
        config: PropTypes.object.isRequired,
        fetchMenu: PropTypes.func.isRequired
      };

      state={
          parentID:'',
          products:[],
          isLoading:true
      }

    async componentDidMount(){
            // console.log('this.props.match.params.name.toLowerCase()', this.props.match.params.name.toLowerCase())
            let searchResult = this.props.menu.filter(f=> f.Name.toLowerCase() ===this.props.match.params.name.toLowerCase() )
            let parentID= ''
            if(searchResult.length > 0)
            {
                // console.log('searchResult[0]', searchResult[0])
                parentID= searchResult[0].ID
                this.setState({parentID:parentID})
            }
            //fetch products
            let products =[]
            // console.log('befoer fetchproducts',products)
            products= await fetchProducts(0, 50, parentID,"")
            // console.log('after fetchproducts',products)
            this.setState({products:products, isLoading:false})
    }

    componentWillReceiveProps(nextProps) {
        
    }

    render()
    {
        const {parentID} = this.state; 
        const {isLoading} = this.state;
        const {products} = this.state;
        
        return <React.Fragment>
            {isLoading && <Spinner/>}
            {!isLoading &&  <React.Fragment> 
                <div className="container">
                    <div className="section-title">{this.props.match.params.name}</div>
                        <MenuThumbNails menu={this.props.menu.filter(f=> f.ParentID ===parentID)} businessUniqueName={this.props.config.BusinessUniqueName} type="subcategory"/>
                        <br/>
                        <br/>
                        {parentID !=="" && <ProductList products={products} />}
                    </div>
                    <br/>
                    <PageFooter config={this.props.config}/>
            </React.Fragment>}
        </React.Fragment>
    };
}


const mapStateToProps = state => ({
    menu: state.api.menu,
    config:state.api.config
});

export default connect(
    mapStateToProps,
    {fetchMenu}
  )(Level1);
  