import { FETCH_CONFIG, FETCH_MENU , FETCH_PRODUCTS, FETCH_PRODUCT_TAXES} from './actionTypes';

const initialState = {
  config:null,
  menu: [],
  products:[],
  productTaxes:[]
};

export default function(state = initialState, action) {
  switch (action.type) {
    case FETCH_CONFIG:
        return {
        ...state,
        config: action.payload
        };
    case FETCH_MENU:
      return {
        ...state,
        menu: action.payload
      };
    case FETCH_PRODUCTS:
      return {
        ...state,
        products: action.payload
      };
    case FETCH_PRODUCT_TAXES:
      return{
        ...state,
        productTaxes:action.payload
      }
    default:
      return state;
  }
}
