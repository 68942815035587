import React ,{Component}from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {BrowserRouter as Router} from "react-router-dom"; 

import Main from '../Main';
import ErrorMsg from '../ErrorMsg';
import Spinner from '../Spinner';
import {fetchConfig, fetchMenu, fetchProductTaxes } from '../../services/api/actions';

class App extends Component {

  static propTypes = {
    fetchConfig:PropTypes.func.isRequired,
    fetchMenu: PropTypes.func.isRequired,
    fetchProductTaxes: PropTypes.func.isRequired,
    config:PropTypes.object
  };

  state={
    merchantExists:false,
    isLoading:false
  }

 async  componentDidMount()
  {
     this.setState({isLoading:true})
     let path = window.location.pathname
     let pathParams = path.split("/")
     if(pathParams.length >1 && pathParams[1] !=="")
     {
        await this.props.fetchConfig(pathParams[1])
        if(this.props.config)
        {
          await this.props.fetchMenu(this.props.config.BusinessUniqueName);
          this.props.fetchProductTaxes(this.props.config.key);
          this.setState({isLoading:false, merchantExists:true}) 
        }
     }
     else
     {
        this.setState({isLoading:false, merchantExists:false}) 
     }
  }
  

  render(){
      const {isLoading} = this.state
      const {merchantExists} = this.state
      return <Router>
            <React.Fragment>
              {isLoading && <Spinner/>}
              {(!isLoading && !merchantExists) && <ErrorMsg errorCode="1"/>}
              {merchantExists && <Main/>}
            </React.Fragment>
      </Router>
  }
}

const mapStateToProps = state => ({
  config:state.api.config
});

export default connect(mapStateToProps,{fetchConfig, fetchMenu, fetchProductTaxes})(App);

