import React from 'react';

import Product from './Product';

const ProductList = ({ products }) => {
  return <div className="row">
    {
      products.map(p => {return <Product product={p} key={p.key} />;})
    }
  </div>
};

export default ProductList;
