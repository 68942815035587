import React from 'react';
import './style.scss';
import logoHome from '../../static/home.png';
import logoContact from '../../static/contact.png';
import logoCart from '../../static/cart.png';
import logoWhatsapp from '../../static/whatsapp.png';
import {useHistory} from 'react-router-dom';
import {showCart} from '../../services/cart/actions';
import {connect} from 'react-redux';


const Footer = ({config, showCart, cartIsOpen, lineItemTotal})=>{
    const history = useHistory();

    const onHomeClick = ()=>{
        history.push("/" + config.BusinessUniqueName); 
    }

    const onContactClick = ()=>{
        history.push("/" + config.BusinessUniqueName + "/Contact") ;
    }

    const onWhatsAppClick =()=>{
        window.open('https://wa.me/91' + config.ContactNo.trim())
    } 

    const onCartClick = () =>{
        showCart(!cartIsOpen);
    }

    return (
        <div className="footer">
            <div className="row" style={{paddingTop:"10px"}}>
                <div className="col-3" onClick={()=>{onHomeClick()}}>
                    <img src={logoHome} alt="home"/>
                    <div className="footer-text">Home</div>
                </div>
                <div className="col-3" onClick={()=>{onContactClick()}}>
                    <img src={logoContact} alt="contact"/>
                    <div className="footer-text">Contact</div>
                </div>
                <div className="col-3" onClick={()=>{onCartClick()}}>
                    <img src={logoCart} alt="cart"/>
                    <span className="footer-bag-quantity">{lineItemTotal.billQty ? lineItemTotal.billQty : 0}</span>
                    <div className="footer-text">Cart</div>
                </div>
                <div className="col-3" onClick={()=>{onWhatsAppClick()}}>
                    <img src={logoWhatsapp} alt="whatsapp"/>
                    <div className="footer-text">Whatsapp</div>
                </div>
            </div>
        </div>
    )
}
        

const mapStateToProps = state => ({
    cartIsOpen: state.cart.cartIsOpen,
    lineItemTotal:state.cart.lineItemTotal
  });
  
  export default connect(
    mapStateToProps,
    {showCart }
  )(Footer);

  