import React, { Component } from 'react';
import { connect } from 'react-redux';
import { checkOut } from '../../services/util';
import { loadCart,loadCartTotal } from '../../services/cart/actions';
import Spinner from '../Spinner';

class Checkout extends Component {

    state = {
        firstName: '',
        lastName: '',
        phoneNo: '',
        emailID: '',
        address1: '',
        address2: '',
        city: '',
        state: '',
        stateCode: '',
        country: '',
        postalCode: '',
        states: [
            { 'code': 1, 'name': '1-JAMMU AND KASHMIR' },
            { 'code': 2, 'name': '2-HIMACHAL PRADESH' },
            { 'code': 3, 'name': '3-PUNJAB' },
            { 'code': 4, 'name': '4-CHANDIGARH' },
            { 'code': 5, 'name': '5-UTTARAKHAND' },
            { 'code': 6, 'name': '6-HARYANA' },
            { 'code': 7, 'name': '7-DELHI' },
            { 'code': 8, 'name': '8-RAJASTHAN' },
            { 'code': 9, 'name': '9-UTTAR PRADESH' },
            { 'code': 10, 'name': '10-BIHAR' },
            { 'code': 11, 'name': '11-SIKKIM' },
            { 'code': 12, 'name': '12-ARUNACHAL PRADESH' },
            { 'code': 13, 'name': '13-NAGALAND' },
            { 'code': 14, 'name': '14-MANIPUR' },
            { 'code': 15, 'name': '15-MIZORAM' },
            { 'code': 16, 'name': '16-TRIPURA' },
            { 'code': 17, 'name': '17-MEGHALAYA' },
            { 'code': 18, 'name': '18-ASSAM' },
            { 'code': 19, 'name': '19-WEST BENGAL' },
            { 'code': 20, 'name': '20-JHARKHAND' },
            { 'code': 21, 'name': '21-ORISSA' },
            { 'code': 22, 'name': '22-CHHATTISGARH' },
            { 'code': 23, 'name': '23-MADHYA PRADESH' },
            { 'code': 24, 'name': '24-GUJARAT' },
            { 'code': 25, 'name': '25-DAMAN AND DIU' },
            { 'code': 26, 'name': '26-DADAR AND NAGAR HAVELI' },
            { 'code': 27, 'name': '27-MAHARASTRA' },
            { 'code': 29, 'name': '29-KARNATAKA' },
            { 'code': 30, 'name': '30-GOA' },
            { 'code': 31, 'name': '31-LAKSHADWEEP' },
            { 'code': 32, 'name': '32-KERALA' },
            { 'code': 33, 'name': '33-TAMIL NADU' },
            { 'code': 34, 'name': '34-PUDUCHERRY' },
            { 'code': 35, 'name': '35-ANDAMAN AND NICOBAR' },
            { 'code': 36, 'name': '36-TELANGANA' },
            { 'code': 37, 'name': '37-ANDHRA PRADESH' },
            { 'code': 97, 'name': '97-OTHER TERRITORY' },
            { 'code': 99, 'name': '99-OTHER COUNTRY' }
        ],
        isLoading: false
    }

    onSubmitClick = async () => {
        //check if mandarty detals are provided
        this.setState({ isLoading: true });

        let validationSuccess = true
        if (this.state.firstName.trim() === "") {
            validationSuccess = false;
        }
        if (this.state.lastName.trim() === "") {
            validationSuccess = false;
        }
        if (this.state.phoneNo.trim() === "") {
            validationSuccess = false;
        }
        if (this.state.address1.trim() === "") {
            validationSuccess = false;
        }
        if (!validationSuccess) {
            this.setState({ validationSuccess: validationSuccess, isLoading: false });
            return false;
        }
        //console.log('checkoutConfig', this.props.config.CheckoutConfig)
        
        if (this.props.config.CheckoutConfig) {
            //continue with saving
            let config = this.props.config;
            let sale = {
                RegistrationID: config.key,
                StoreID: config.CheckoutConfig.StoreID,
                CashRegisterID: config.CheckoutConfig.CashRegisterID,
                TransactionTypeID: config.CheckoutConfig.TransactionTypeID,
                RequestSource: 'omni web',
                RequestSourceID: 'omni web',
                Customer: {
                    CustomerCode: '',
                    FirstName: this.state.firstName,
                    LastName: this.state.lastName,
                    PhoneNo: this.state.phoneNo,
                    EmailID: this.state.emailID,
                    Tag: '',
                    DOBNew:{ Day: '', Month: '', Year: '' },
                    AnniversaryNew:{ Day: '', Month: '', Year: '' },
                    Membership:null,
                    WalletOpeningBalance: 0,
                    WalletBalance: 0,
                    OpeningOutStandingAmount: 0,
                    OpeningOutStandingAmountCurrent:0,
                    OpeningOutStandingAmountReceived:0,
                    OutStandingAmount:0,
                    OutStandingAmountReceived:0,
                    CustomerAcceptsMarketing: true,
                    Note:'',
                    Gender:'',
                    CustomField1: '',
                    CustomField2: '',
                    CustomField3: '',
                    CustomField4: '',
                    CustomField5: '',
                    CustomField6: '',
                    StoreID: config.CheckoutConfig.StoreID,
                    ReferredBy:'',
                    Addresses: [
                        {
                            Address1: this.state.address1,
                            Address2: this.state.address2,
                            AddressType: "Home",
                            City: this.state.city,
                            State: this.state.state,
                            Country: this.state.country,
                            PostalCode: this.state.postalCode
                        }
                    ],
                    SaleToPurchaseStoreID:'',
                    SaleToPurchaseStore:'',
                    BackEndUpdate: false,
                    IsDeleted: false,
                },
                LineItems: [],
                BillAmount: this.props.lineItemTotal.billAmount,
                BillBalanceAmount: 0,
                BillChangeDue: 0,
                BillDiscount: this.props.lineItemTotal.billDiscount,
                BillPaidAmount: this.props.lineItemTotal.billAmount,
                BillQty: this.props.lineItemTotal.billQty,
                BillSubTotal: this.props.lineItemTotal.billSubTotal,
                BillTaxAmount: this.props.lineItemTotal.billTaxAmount
            }
            let lineItems = this.props.lineItems
            lineItems.map(m => {
                let lineItem = {
                    RetailPrice: m.RetailPrice,
                    DiscountPer: m.DiscountPer,
                    DiscountAmount: m.DiscountAmount,
                    Qty: m.Qty,
                    SubTotal: m.SubTotal,
                    ChargeTaxOnProduct: m.ChargeTaxOnProduct,
                    PriceBasedTax: m.PriceBasedTax,
                    HSNSACCode: m.HSNSACCode,
                    TaxInclusive: m.TaxInclusive,
                    TaxGroup: m.TaxGroup,
                    TaxID: m.TaxID,
                    SubTotalWithDiscount: m.SubTotalWithDiscount,
                    TaxableAmount: m.TaxableAmount,
                    TotalTax: m.TotalTax,
                    Amount: m.Amount,
                    ProductID: m.Product.key,
                    BasePrice: m.TaxableAmount,
                    TaxComponentAmount: m.TaxComponentAmount,
                    Notes: ''
                }
                sale.LineItems.push(lineItem);
            })
            //console.log('sale', sale);
            let response = await checkOut(sale)
            // console.log('page - checkout response', response);
            if(response.status === 200 && response.data.status === "Success")
            {
                this.setState({
                    firstName: '',
                    lastName: '',
                    phoneNo: '',
                    emailID: '',
                    address1: '',
                    address2: '',
                    city: '',
                    state: '',
                    stateCode: '',
                    country: '',
                    postalCode: '',
                    isLoading: false
                });
                this.props.loadCart([]);
                this.props.loadCartTotal({
                    billQty: 0,
                    billSubTotal: 0,
                    billAmount: 0,
                    billTaxAmount: 0,
                    billDiscount: 0,
                    taxSummaryList: [],
                    roundOff: 0
                });
                alert('Order Placed successfully');
                // useHistory().push("/" + this.props.config.BusinessUniqueName);
            }
            else
            {
                this.setState({ isLoading: false });
                alert('Order creation failed. Please contact support@posible.in');
            }
        }
        else {
            this.setState({ isLoading: false });
            alert('Required checkout settings missing. Please contact support@posible.in');
        }
    }

    render() {

        const { lineItems } = this.props;
        const { lineItemTotal } = this.props;
        const { isLoading } = this.state;

        return <React.Fragment>
            {isLoading && <Spinner />}
            {!isLoading && <div className="container">
                <h2>Checkout</h2>
                <div className="row">
                    <div className="col-md-4 order-md-2 mb-4">
                        <h4 className="d-flex justify-content-between align-items-center mb-3">
                            <span className="text-muted">Your cart</span>
                            <span className="badge badge-secondary badge-pill">{lineItemTotal.billQty}</span>
                        </h4>
                        <ul className="list-group mb-3">
                            {lineItems.map(m =>
                                <li className="list-group-item d-flex justify-content-between lh-condensed">
                                    <div>
                                        <h6 className="my-0">{m.Product.Name}</h6>
                                        <small className="text-muted">{m.Product.SKU}</small>
                                    </div>
                                    <span className="text-muted">{m.Amount}</span>
                                </li>)}
                            {/* <li className="list-group-item d-flex justify-content-between bg-light">
                        <div className="text-success">
                            <h6 className="my-0">Promo code</h6>
                            <small>EXAMPLECODE</small>
                        </div>
                        <span className="text-success">-$5</span>
                        </li> */}
                            <li className="list-group-item d-flex justify-content-between">
                                <span>Total (INR)</span>
                                <strong>{lineItemTotal.billAmount}</strong>
                            </li>
                        </ul>
                        {/* <form className="card p-2">
                        <div className="input-group">
                        <input type="text" className="form-control" placeholder="Promo code"/>
                        <div className="input-group-append">
                            <button type="submit" className="btn btn-secondary">Redeem</button>
                        </div>
                        </div>
                    </form> */}
                    </div>
                    <div className="col-md-8 order-md-1">
                        <h4 className="mb-3">Billing address</h4>
                        {/* <form className="needs-validation" novalidate=""> */}
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label >First name</label>
                                <input type="text" className="form-control" placeholder=""
                                    onChange={(e) => this.setState({ firstName: e.target.value })}
                                    value={this.state.firstName} required={true} />
                                <div className="invalid-feedback">
                                    Pelase provide first name.
                            </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label>Last name</label>
                                <input type="text" className="form-control" placeholder=""
                                    onChange={(e) => this.setState({ lastName: e.target.value })}
                                    value={this.state.lastName} required="" />
                                <div className="invalid-feedback">
                                    Please provide last name.
                            </div>
                            </div>
                        </div>

                        {/* <div className="mb-3">
                    <label "username">Username</label>
                    <div className="input-group">
                        <div className="input-group-prepend">
                        <span className="input-group-text">@</span>
                        </div>
                        <input type="text" className="form-control" id="username" placeholder="Username" required=""/>
                        <div className="invalid-feedback" style={{width: "100%"}}>
                        Your username is required.
                        </div>
                    </div>
                    </div> */}
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label>Phone No</label>
                                <input type="email" className="form-control" placeholder="Contact No"
                                    value={this.state.phoneNo}
                                    onChange={(e) => { this.setState({ phoneNo: e.target.value }) }}
                                />
                                <div className="invalid-feedback">
                                    Please provide valid mobile no.
                            </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label>Email <span className="text-muted">(Optional)</span></label>
                                <input type="email" className="form-control"
                                    value={this.state.emailID}
                                    onChange={(e) => { this.setState({ emailID: e.target.value }) }}
                                    placeholder="you@example.com" />
                                {/* <div className="invalid-feedback">
                                Please enter a valid email address for shipping updates.
                            </div> */}
                            </div>
                        </div>
                        <div className="mb-3">
                            <label>Address</label>
                            <input type="text" className="form-control" placeholder=""
                                value={this.state.address1}
                                onChange={(e) => this.setState({ address1: e.target.value })}
                                required="" />
                            <div className="invalid-feedback">
                                Please enter your shipping address.
                        </div>
                        </div>
                        <div className="mb-3">
                            <label>Address 2 <span className="text-muted">(Optional)</span></label>
                            <input type="text" className="form-control"
                                value={this.state.address2}
                                onChange={(e) => this.setState({ address2: e.target.value })}
                                placeholder="" />
                        </div>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label >City</label>
                                <input type="text" className="form-control"
                                    value={this.state.city}
                                    onChange={(e) => this.setState({ city: e.target.value })}
                                    placeholder="" required={true} />
                                <div className="invalid-feedback">
                                    Please provide city
                            </div>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label>State</label>
                                <select className="custom-select d-block w-100" id="state"
                                    value={this.state.stateCode}

                                    onChange={(evt) => this.setState({ stateCode: evt.target.value, state: evt.target.options[evt.target.selectedIndex].text })}>
                                    required={true}>
                            <option value="">Choose...</option>
                                    {this.state.states.map(m => <option value={m.code}>{m.name}</option>)}
                                </select>
                                {/* <div className="invalid-feedback">
                            Please provide a valid state.
                            </div> */}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label>Country</label>
                                <select className="custom-select d-block w-100" >
                                    <option value="India">India</option>
                                </select>
                                {/* <div className="invalid-feedback">
                            Please select a valid country.
                            </div> */}
                            </div>

                            <div className="col-md-6 mb-3">
                                <label >Postal Code</label>
                                <input type="text"
                                    className="form-control"
                                    value={this.state.postalCode}
                                    onChange={(e) => this.setState({ postalCode: e.target.value })}
                                    placeholder="" />
                                {/* <div className="invalid-feedback">
                            Zip code required.
                            </div> */}
                            </div>
                        </div>
                        {/* <hr className="mb-4"/>
                    <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" id="same-address"/>
                    <label className="custom-control-label" "same-address">Shipping address is the same as my billing address</label>
                    </div>
                    <div className="custom-control custom-checkbox">
                    <input type="checkbox" className="custom-control-input" id="save-info"/>
                    <label className="custom-control-label" "save-info">Save this information for next time</label>
                    </div> */}
                        <hr className="mb-4" />

                        <h4 className="mb-3">Payment</h4>

                        <div className="d-block my-3">
                            <div className="custom-control custom-radio">
                                <input id="credit" name="paymentMethod" type="radio" className="custom-control-input" checked={true} required="" />
                                <label className="custom-control-label">Manual / COD</label>
                            </div>
                        </div>
                        {/* <div className="row">
                    <div className="col-md-6 mb-3">
                        <label "cc-name">Name on card</label>
                        <input type="text" className="form-control" id="cc-name" placeholder="" required=""/>
                        <small className="text-muted">Full name as displayed on card</small>
                        <div className="invalid-feedback">
                        Name on card is required
                        </div>
                    </div>
                    <div className="col-md-6 mb-3">
                        <label "cc-number">Credit card number</label>
                        <input type="text" className="form-control" id="cc-number" placeholder="" required=""/>
                        <div className="invalid-feedback">
                        Credit card number is required
                        </div>
                    </div>
                    </div> */}
                        {/* <div className="row">
                    <div className="col-md-3 mb-3">
                        <label "cc-expiration">Expiration</label>
                        <input type="text" className="form-control" id="cc-expiration" placeholder="" required=""/>
                        <div className="invalid-feedback">
                        Expiration date required
                        </div>
                    </div>
                    <div className="col-md-3 mb-3">
                        <label "cc-cvv">CVV</label>
                        <input type="text" className="form-control" id="cc-cvv" placeholder="" required=""/>
                        <div className="invalid-feedback">
                        Security code required
                        </div>
                    </div>
                    </div> */}
                        <hr className="mb-4" />
                        <button className="btn btn-primary btn-lg btn-block" onClick={() => this.onSubmitClick()}>Continue to checkout</button>
                        {/* </form> */}
                    </div>
                </div>
                <br />
                <br />
                <br />
                <br />
            </div>}
        </React.Fragment> 
    }
}

const mapStateToProps = state => ({
    lineItems: state.cart.lineItems,
    lineItemTotal: state.cart.lineItemTotal,
    config: state.api.config
});

export default connect(
    mapStateToProps,
    { loadCart, loadCartTotal }
)(Checkout);