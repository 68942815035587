import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addProduct } from '../../../services/cart/actions';
import {useHistory} from 'react-router-dom';

const Product = ({ product, addProduct, config }) => {
  const history = useHistory()
  product.Quantity = 1;

  const getThumbNailImage= (m, className)=>{
    let url = ''
    // console.log(m)
    if(m.Images && m.Images.length > 0)
    {
      if(m.Images[0])
      {
          if(m.Images[0].ImagePath !=="")
          {
              url = m.Images[0].ImagePath
          }
      }
    }

    if(url !=="")
    {
        return <img src={url} className={className} alt="..."/>
        // "card-img-top menu-thumbnail-image"
    }
    else
    {
      return  <svg className="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img">
      <rect width="100%" height="100%" fill="#777"></rect>
      <text x="40%" y="50%" fill="#eceeef" style={{fontSize:"50px"}}>{m.Name.substring(0, 1)}</text>
      </svg>
    }
  };


  return (
    <div className="col-md-3 col-6 " data-sku={product.sku}>
      <div className="card">
              {getThumbNailImage(product,"card-img-top")}
              <div className="product-thumbnail-body">
                  <div className="product-thumbnail-title" onClick={()=> history.push("/" + config.BusinessUniqueName + "/p/" + product.Name)} >{product.Name}</div>
                  <div className="product-thumbnail-price">₹ {product.RetailPrice}</div>
                  <div className="product-thumbnail-addtocart">
                    <button onClick={() => addProduct(product)}  className="btn" >Add to cart</button>
                  </div>
              </div>
        </div>
      </div>
  );
};

Product.propTypes = {
  product: PropTypes.object,
  addProduct: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  config: state.api.config
});

export default connect(
  mapStateToProps,
  { addProduct }
)(Product);
