import React ,{Component}from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {BrowserRouter as Router,Route} from 'react-router-dom'

import Home from '../Home';
import Level1 from '../Level1';
import Level2 from '../Level2';
import Level3 from '../Level3';
import Spinner from '../Spinner';
import Menu from '../Menu';
import Footer from '../Footer';
import Contact from '../Contact';
import Cart from '../Cart';
import Checkout from '../Checkout';

class Main extends Component {

  static propTypes = {
    // fetchMenu:PropTypes.func.isRequired,
    // fetchProductTaxes: PropTypes.func.isRequired,
    config:PropTypes.object
  };

  state={
    isLoading:false
  }

 async  componentDidMount()
  {
    
  }

  render(){
      const {isLoading} = this.state
      return <Router>
          <React.Fragment>
            {isLoading && <Spinner/>}
            {!isLoading && <React.Fragment>
                  <Route exact path="/:business" component={Home}/>
                  <Route exact path={"/" + this.props.config.BusinessUniqueName + "/contact"} component={Contact}/>
                  <Route path={ "/" + this.props.config.BusinessUniqueName + "/l1/:name"} component={Level1}/>
                  <Route path={ "/" + this.props.config.BusinessUniqueName + "/l2/:name"} component={Level2}/>
                  <Route path={ "/" + this.props.config.BusinessUniqueName + "/p/:name"} component={Level3}/>
                  <Route exact path={"/" + this.props.config.BusinessUniqueName +"/checkout"} component={Checkout}/>
                <Footer config={this.props.config}/>
                <Menu/>
                <Cart/>
            </React.Fragment>}
        </React.Fragment>
      </Router>
  }
}

const mapStateToProps = state => ({
  config:state.api.config,
  menu:state.api.menu
});

export default connect(
  mapStateToProps,
  {}
)(Main);



