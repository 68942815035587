import React from 'react';
// import {getImage} from '../../services/util'
import {useHistory} from 'react-router-dom';


const MenuThumbNails = ({ menu, businessUniqueName, type = "category" }) => {
    const history = useHistory()

    // const image =(m)=>{
    //     let imageURL = getImage(m)
    //     <img src={getImage(m)} className="card-img-top" alt="..."/>
    // }

    const getThumbNailImage= (m, className)=>{
        let url = ''
        if(m.Image)
        {
            if(m.Image.Preview !=="")
            {
                url = m.Image.Preview
            }
        }
        if(url !=="")
        {
            return <img src={m.Image.Preview} className={className} alt="..."/>
            // "card-img-top menu-thumbnail-image"
        }
        else
        {
          return  <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img">
          <rect width="100%" height="100%" fill="#777"></rect>
          <text x="40%" y="50%" fill="#eceeef" style={{fontSize:"50px"}}>{m.Name.substring(0, 1)}</text>
          </svg>
        }
      };

    return(
         <div className="row"> 
            {menu.map(m =><div  key={m.ID} className="col-6 col-md-4 col-lg-3">
                <div className="card" onClick={()=> history.push("/" + businessUniqueName + (type === "category" ? "/l1/" : "/l2/") + m.Name)}>
                        {getThumbNailImage(m,"card-img-top menu-thumbnail-image")}
                        <div className="card-body menu-thumbnail-body">
                            <span className="menu-thumbnail-title">{m.Name}</span>
                        </div>
                </div>
        </div>)
          }
      </div>
    )  
}

export default MenuThumbNails;