import axios from 'axios';

export const formatPrice = (x, currency) => {
  // switch (currency) {
    // case 'BRL':
    //   return x.toFixed(2).replace('.', ',');
    // default:
    //   return x.toFixed(2);
  // }
  return x;
};

export const fetchProduct = (productName) => {
  return axios
    .post(productAPI, { productName: productName })
    .then(res => {
      let { product } = res.data;
      console.log(product);
      return product;
    })
    .catch(err => {
      console.log('Could not fetch products. Try again later.');
    });
};

export const fetchProducts = (from, size, categoryID, subCategoryID) => {
  return axios
    .post(productsAPI, {from:from, size:size,categoryID: categoryID, subCategoryID:subCategoryID })
    .then(res => {
      let { products } = res.data;
      return products;
    })
    .catch(err => {
      console.log('Could not fetch products. Try again later.');
    });
};

export const fetchLatestProducts = (key , from, size) => {
  return axios
    .post(latestProductsAPI, {from:from, size:size ,key:key})
    .then(res => {
      let { products } = res.data;
      console.log('products',)
      return products;
    })
    .catch(err => {
      console.log('Could not fetch products. Try again later.');
    });
};

export const fetchLatestCarousels = (key) => {
  return axios
    .post(carouselPI, { key: key })
    .then(res => {
      let { carousels } = res.data;
      // console.log('products')
      return carousels;
    })
    .catch(err => {
      console.log('Could not fetch carousels. Try again later.');
    });
};

export const submitInquiry = (inquiry) => {
  // console.log('submitInquiry inquiry',inquiry)
  return axios
    .post(submitInquiryAPI, {...inquiry})
    .then(res => {
      // let { products } = res.data;
      // console.log('submitInquiry', res)
      return res;
    })
    .catch(err => {
      console.log('submitInquiry', err);
    });
};


export const checkOut = (checkout) => {
  console.log('checkOut inquiry',checkout)
  return axios
    .post(checkOutAPI, { Sale: { ...checkout } })
    .then(res => {
      // let { products } = res.data;
      console.log('checkOut response', res)
      return res;
    })
    .catch(err => {
      console.log('checkOut error', err);
    });
};

export const menuAPI = 'https://us-central1-posible-app-prod.cloudfunctions.net/omniGetMenu';
export const carouselPI = 'https://us-central1-posible-app-prod.cloudfunctions.net/omniGetCarousels';
export const configAPI = 'https://us-central1-posible-app-prod.cloudfunctions.net/omniGetConfig';
export const productsAPI = 'https://us-central1-posible-app-prod.cloudfunctions.net/omniGetProducts';
export const productAPI = 'https://us-central1-posible-app-prod.cloudfunctions.net/omniGetProduct';
export const latestProductsAPI = 'https://us-central1-posible-app-prod.cloudfunctions.net/omniGetLatestProducts';
export const submitInquiryAPI = 'https://us-central1-posible-app-prod.cloudfunctions.net/omniSumbitInquiry';
export const productTaxesAPI = 'https://us-central1-posible-app-prod.cloudfunctions.net/omniProductTaxes';
export const checkOutAPI = 'https://us-central1-posible-app-prod.cloudfunctions.net/apiCreateSales';