import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchProduct } from '../../services/util';
import Spinner from '../Spinner';
import { addProduct } from '../../services/cart/actions';
import PageFooter from '../PageFooter';
import {withRouter} from 'react-router-dom';



const getThumbNailImage = (m, className) => {
    let url = ''
    // console.log(m)
    if (m.Images && m.Images.length > 0) {
        if (m.Images[0]) {
            if (m.Images[0].ImagePath !== "") {
                url = m.Images[0].ImagePath
            }
        }
    }

    if (url !== "") {
        return <img src={url} className={className} alt="..." />
        // "card-img-top menu-thumbnail-image"
    }
    else {
        return <svg className="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" preserveAspectRatio="xMidYMid slice" focusable="false" role="img">
            <rect width="100%" height="100%" fill="#777"></rect>
            <text x="40%" y="50%" fill="#eceeef" style={{ fontSize: "50px" }}>{m.Name.substring(0, 1)}</text>
        </svg>
    }
};

class Level3 extends Component {

    static propTypes = {
        menu: PropTypes.array.isRequired,
        config: PropTypes.object.isRequired,
    };

    state = {
        product: null,
        isLoading: true
    }

    async componentDidMount() {
        //fetch product
        let product = null
        product = await fetchProduct(this.props.match.params.name.toLowerCase())
        if(product)
            product.Quantity = 1;
        this.setState({ product: product, isLoading: false })
    }

    async componentWillReceiveProps(nextProps) {
        //fetch product
        let product = null
        product = await fetchProduct(nextProps.match.params.name.toLowerCase())
        if(product)
            product.Quantity = 1;
        this.setState({ product: product, isLoading: false })
    }

    
    handleOnIncrease = (product) => {
        product.Quantity = Number(product.Quantity) + 1;
        this.setState({ product: product})
      }
    
      handleOnDecrease = (product) => {
          product.Quantity = Number(product.Quantity) - 1;
          this.setState({ product: product })
      }

    onWhatsAppClick =()=>{
        let url = encodeURI('https://wa.me/91' + this.props.config.ContactNo.trim() + 
        "?text=Hello " + this.props.config.BusinessUniqueName + "\r\nI want to order " 
        + "*" + this.state.product.Name.trim().replace('&','and') + "*" + "\r\n" + "\u2705 dev.omni.posible.in/" + this.props.config.BusinessName);
        // console.log(url); 
        window.open(url);
    }

    render() {
        const { isLoading } = this.state;
        const { product } = this.state;

        return <React.Fragment>
            {isLoading && <Spinner />}
            {!isLoading && <React.Fragment>
                <div className="container">
                    <div className="section-title">{this.props.match.params.name}</div>
                    <br />
                    <br />
                    {product && <React.Fragment>
                        <div className="row">
                            <div className="col-md-3 col-6 " data-sku={product.sku}>
                                <div className="card">
                                    {getThumbNailImage(product, "card-img-top")}
                                    <div className="product-thumbnail-body">
                                        <div className="product-thumbnail-title">{product.Name}</div>
                                        <div className="product-thumbnail-price">₹ {product.RetailPrice}</div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-3 col-6 ">
                                {/* <div className="row">
                                    <div className="col-md-6 col-6 ">
                                        <div className="product-thumbnail-title">₹ {product.RetailPrice}</div>
                                    </div>
                                </div> */}
                                <div className="row" style={{marginTop: "10px", marginBottom: "10px"}}>
                                    <div className="col-md-6 col-6 ">
                                        <div className="btn-group">
                                            <button type="button" className="btn btn-default" onClick={() => this.handleOnDecrease(product)} disabled={product.Quantity === 0 ? true : false} > - </button>
                                            <input type="text" className="form-control" value={product.Quantity} style={{ width: "50px" }} />
                                            <button type="button" className="btn btn-default" onClick={() => this.handleOnIncrease(product)} > + </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6 col-12 ">
                                        <button  style={{width: "100%"}} type="button" className="btn btn-info" onClick={()=> this.props.history.push("/" + this.props.config.BusinessUniqueName + "/Contact") } 
                                        >Enquire</button> 
                                    </div>
                                    <div className="col-md-6 col-12 ">
                                        <button type="button" className="btn btn-primary" onClick={() => this.props.addProduct(product)} style={{width: "100%"}} >Add To Cart</button>
                                    </div>
                                </div>
                                <div className="row" style={{marginTop: "10px", marginBottom: "10px"}}>
                                    <div className="col-md-12 col-12 ">
                                        <button type="button" className="btn btn-success" onClick={()=> this.onWhatsAppClick()}  style={{width: "100%"}} >Order On WhatsApp</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </React.Fragment> }
                </div>
                <br />
                <PageFooter config={this.props.config} />
            </React.Fragment>}
        </React.Fragment>
    };
}


const mapStateToProps = state => ({
    menu: state.api.menu,
    config: state.api.config
});


export default withRouter(connect(
    mapStateToProps,
    { addProduct }
)(Level3));
