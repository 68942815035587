import React, { Component } from 'react';
import {withRouter} from 'react-router-dom';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { loadCart, removeProduct, changeProductQuantity, loadCartTotal , showCart} from '../../services/cart/actions';
import CartProduct from './CartProduct';

import './style.scss';

class Cart extends Component {
  static propTypes = {
    loadCart: PropTypes.func.isRequired,
    loadCartTotal: PropTypes.func.isRequired,
    showCart: PropTypes.func.isRequired,
    productTaxes:PropTypes.array.isRequired,
    config:PropTypes.object.isRequired,
    lineItems:PropTypes.array.isRequired,
    lineItemTotal:PropTypes.object.isRequired,
    newProduct: PropTypes.object,
    removeProduct: PropTypes.func,
    productToRemove: PropTypes.object,
    changeProductQuantity: PropTypes.func,
    productToChange: PropTypes.object,
  };

  state = {
    isOpen: false
  };

  componentWillReceiveProps(nextProps) {
      // console.log('nextProps',nextProps);
    if (nextProps.newProduct !== this.props.newProduct) {
      this.addProduct(nextProps.newProduct);
    }

    if (nextProps.productToRemove !== this.props.productToRemove) {
      this.removeProduct(nextProps.productToRemove);
    }

    if (nextProps.productToChange !== this.props.productToChange) {
      this.changeProductQuantity(nextProps.productToChange);
    }
  }

  openFloatCart = () => {
    this.setState({ isOpen: true });
  };

  closeCart = () => {
    this.props.showCart(false);
  };

  proceedToCheckout = () => {
    this.closeCart();
    this.props.history.push('/' + this.props.config.BusinessUniqueName + '/checkout');
  };

  addProduct = product => {
    //   console.log('product',product)
      const { lineItems, loadCart ,loadCartTotal } = this.props;
      let lineItemIndex = -1;
      let lineItem = {};
      let cartTotal ={}
        for(let i=0;i<lineItems.length;i++)
        {
          if (lineItems[i].Product.key === product.key) {
            lineItemIndex = i;
            lineItem= lineItems[i];
          }
        }

        if (lineItemIndex === -1) {
            lineItem.RetailPrice = product.RetailPrice
            lineItem.DiscountPer = product.hasOwnProperty('DiscountPercentage') ? product.DiscountPercentage : 0
            lineItem.DiscountAmount = 0
            lineItem.Qty = 1
            lineItem.SubTotal = 0
            lineItem.ChargeTaxOnProduct = product.ChargeTaxOnProduct
            lineItem.PriceBasedTax = product.ChargeTaxOnProduct ? product.PriceBasedTax : false
            lineItem.HSNSACCode = product.HSNSACCode
            lineItem.TaxInclusive = product.ChargeTaxOnProduct ? product.TaxInclusive : false
            lineItem.TaxGroup = product.TaxGroup
            lineItem.TaxID = product.TaxID
            lineItem.SubTotalWithDiscount = 0
            lineItem.TaxableAmount = 0
            lineItem.TotalTax = 0
            lineItem.Amount = 0
            lineItem.Product = product
            lineItem.Employee = {key:''}
            lineItem.Notes = ''
        }
        else {
              lineItem.Qty =  Number(lineItem.Qty) + 1
        } 
        lineItem = this.onLineItemChange(lineItem)
        if (lineItemIndex >= 0) {
            lineItems[lineItemIndex] = lineItem
        }
        else {
            lineItems.unshift(lineItem)
        }
        cartTotal = this.calculateBillTotal(lineItems)
        // console.log('lineItems',lineItems);
        // console.log('cartTotal', cartTotal);
        loadCart(lineItems)
        loadCartTotal(cartTotal);
  };

  removeProduct = changedLineItem => {
    const { lineItems, loadCart, loadCartTotal } = this.props;
    const lineItemIndex = lineItems.findIndex(p=> p.Product.key === changedLineItem.Product.key);
    
    if (lineItemIndex >= 0) {
      lineItems.splice(lineItemIndex, 1);
      let cartTotal = this.calculateBillTotal(lineItems);
      loadCart(lineItems);
      loadCartTotal(cartTotal);
    }
  };

  changeProductQuantity = changedLineItem => {
    const { lineItems, loadCart, loadCartTotal } = this.props;
    const lineItemIndex = lineItems.findIndex(p=> p.Product.key === changedLineItem.Product.key);
    if(lineItemIndex >=0)
    {
      if (changedLineItem.Qty <= 0) {
        this.removeProduct(changedLineItem);
      }
      else
      {
        let lineItem = lineItems[lineItemIndex];
        lineItem.Qty = changedLineItem.Qty;
        lineItem = this.onLineItemChange(lineItem);
        lineItems[lineItemIndex] = lineItem;
        let cartTotal = this.calculateBillTotal(lineItems);
        loadCart(lineItems);
        loadCartTotal(cartTotal);
      }
    }
  }

  roundUpTo2(num){
    return Math.round(Number(num) * 100) / 100
  }

  onLineItemChange(lineItem) {

      let subTotal = this.roundUpTo2(Number(lineItem.RetailPrice) * Number(lineItem.Qty)) 
      let discountAmt =0 
      let discountPer = 0
      let allowDiscount = true 
      if(lineItem.Product.hasOwnProperty('AllowDiscount'))
      {
          allowDiscount = lineItem.Product.AllowDiscount
      }
      if(allowDiscount){
          
          if (Number(lineItem.DiscountAmount) > 0 && Number(lineItem.DiscountPer) === 0) {
              //calculate discount per based on discount amount
              discountPer = Number(((Number(lineItem.DiscountAmount) * 100) / Number(lineItem.SubTotal)).toFixed(3))
              discountAmt = this.roundUpTo2(lineItem.DiscountAmount)
          }
          else {
              discountPer = Number(lineItem.DiscountPer)
              discountAmt = this.roundUpTo2((Number(subTotal) * Number(lineItem.DiscountPer)) / 100)
          }
      }
      else
      {
          lineItem.Discounts = []
      }
      
      let subTotalWithDiscount = this.roundUpTo2(Number(subTotal) - Number(discountAmt))
      let chargeTaxOnProduct = lineItem.ChargeTaxOnProduct
      let priceBasedTax = lineItem.PriceBasedTax
      let taxInclusive = lineItem.TaxInclusive
      let taxableAmount = 0
      let totalTax = 0
      let amount = 0
      let taxComponentAmount = []
      
      if (chargeTaxOnProduct) {
          if (priceBasedTax) {
              let unitPriceAfterDiscount = this.roundUpTo2(Number(subTotalWithDiscount )/ Number(lineItem.Qty))
              lineItem.Product.TaxPriceRange.map((t) => {
                  if (Number(unitPriceAfterDiscount) >= Number(t.FromPrice) && Number(unitPriceAfterDiscount) <= Number(t.ToPrice)) {
                      lineItem.TaxID = t.TaxID
                      lineItem.TaxGroup = t.TaxGroup
                      return
                  }
              })
          }
          let taxfound = false
          let customerFromSameState = true //TBD IMP IMP

          this.props.productTaxes.filter((t) => t.key === lineItem.TaxID.trim()).map((taxGroup, index) => {
              taxfound = true
              let taxAmount = 0
              let taxComponentTemp = []
              taxGroup.Taxes.map((t) => {
                  if (customerFromSameState && (t.TaxType === "CGST" || t.TaxType === "SGST")) {
                      taxComponentTemp.push({
                          TaxName: t.TaxName, TaxPercentage: Number(t.TaxPercentage),
                          TaxType: t.TaxType, TaxAmount: 0
                      })
                  }
                  else if (!customerFromSameState && t.TaxType === "IGST") {
                      taxComponentTemp.push({
                          TaxName: t.TaxName, TaxPercentage: Number(t.TaxPercentage),
                          TaxType: t.TaxType, TaxAmount: 0
                      })
                  }
                  else if (t.TaxType !== "CGST" && t.TaxType !== "IGST" && t.TaxType !== "SGST") {
                      taxComponentTemp.push({
                          TaxName: t.TaxName, TaxPercentage: Number(t.TaxPercentage),
                          TaxType: t.TaxType, TaxAmount: 0
                      })
                  }
              })

              if (taxInclusive) {//tax inclusive
                  let totalTaxRate = 0
                  taxComponentTemp.map((t) => {
                      totalTaxRate = this.roundUpTo2(Number(t.TaxPercentage) + Number(totalTaxRate))
                  })
                  totalTax = this.roundUpTo2(Number(subTotalWithDiscount) * Number(totalTaxRate) / (100 + Number(totalTaxRate)))
                  taxableAmount = this.roundUpTo2(Number(subTotalWithDiscount) - Number(totalTax))
                  amount = this.roundUpTo2(subTotalWithDiscount)
                  taxComponentTemp.map((t) => {
                      taxAmount = this.roundUpTo2(Number(totalTax) * ((Number(t.TaxPercentage) * 100) / Number(totalTaxRate)) / 100)
                      taxComponentAmount.push({
                          TaxName: t.TaxName, TaxPercentage: Number(t.TaxPercentage),
                          TaxType: t.TaxType, TaxAmount: taxAmount
                      })
                  })
              }
              else {
                  taxableAmount = subTotalWithDiscount
                  taxComponentTemp.map((t) => {
                      taxAmount = this.roundUpTo2((Number(taxableAmount) * Number(t.TaxPercentage)) / 100)
                      totalTax = this.roundUpTo2(Number(totalTax) + Number(taxAmount))
                      taxComponentAmount.push({
                          TaxName: t.TaxName, TaxPercentage: Number(t.TaxPercentage),
                          TaxType: t.TaxType, TaxAmount: taxAmount
                      })
                  })
                  amount = this.roundUpTo2(taxableAmount + totalTax)
              }
          })
          //tax is not found then
          if (!taxfound) {
              //alert("Tax not found for selected product. Please check item ")
              taxableAmount = 0
              taxComponentAmount = []
              totalTax = 0
              amount = subTotalWithDiscount
          }
      }
      else {
          taxableAmount = 0
          taxComponentAmount = []
          totalTax = 0
          amount = subTotalWithDiscount
      }
      lineItem.SubTotal = this.roundUpTo2(subTotal)
      lineItem.ChargeTaxOnProduct = chargeTaxOnProduct
      lineItem.TaxInclusive = taxInclusive
      lineItem.DiscountPer = this.roundUpTo2(discountPer)
      lineItem.DiscountAmount =this.roundUpTo2( discountAmt)
      lineItem.SubTotalWithDiscount = this.roundUpTo2(subTotalWithDiscount)
      lineItem.TaxableAmount = this.roundUpTo2(taxableAmount)
      lineItem.TaxComponentAmount = taxComponentAmount
      lineItem.TotalTax = this.roundUpTo2(totalTax)
      lineItem.Amount = this.roundUpTo2(amount)
      return lineItem
  }

  calculateBillTotal(lineItems) {
    let billQty = 0
    let billAmount = 0
    let billSubTotal = 0
    let billTaxAmount = 0
    let billDiscount = 0
    let taxSummaryList =[]
    let roundOff = 0 
    lineItems.map((lineItem) => {
        if(lineItem.TaxID !="")
        {
            let indexFound = -1 
            taxSummaryList.map((t, index )=>{
                if(t.TaxID === lineItem.TaxID && t.HSNSACCode === lineItem.HSNSACCode)
                {
                    let sameTaxComponent = true
                    t.TaxComponentAmount.map((a)=>{
                       if(lineItem.TaxComponentAmount.filter(f=> f.TaxName === a.TaxName).length === 0)
                       {
                        sameTaxComponent= false
                        return
                       }
                    })
                    if(sameTaxComponent){
                        indexFound = index
                        return 
                    }
                }
            })
            if(indexFound < 0 )
            {
                let taxSummary = {TaxID: lineItem.TaxID,
                TaxGroup: lineItem.TaxGroup,
                HSNSACCode: lineItem.HSNSACCode,
                TaxableAmount: this.roundUpTo2(lineItem.TaxableAmount),
                TotalTax:this.roundUpTo2(lineItem.TotalTax),
                TotalQty: this.roundUpTo2(lineItem.Qty),
                TaxComponentAmount:[]
                 }
                 lineItem.TaxComponentAmount.map((a)=>{
                    taxSummary.TaxComponentAmount.push({...a})
                 })
                taxSummaryList.push(taxSummary)
            }
            else{
                taxSummaryList[indexFound].TaxableAmount = this.roundUpTo2(Number(taxSummaryList[indexFound].TaxableAmount) +  Number(lineItem.TaxableAmount))
                taxSummaryList[indexFound].TotalTax = this.roundUpTo2(Number(taxSummaryList[indexFound].TotalTax) +  Number(lineItem.TotalTax))
                taxSummaryList[indexFound].TotalQty =  this.roundUpTo2(Number(taxSummaryList[indexFound].TotalQty) +  Number(lineItem.Qty))
                taxSummaryList[indexFound].TaxComponentAmount.map((t,index)=>{
                    t.TaxAmount = this.roundUpTo2(Number(t.TaxAmount) + Number(lineItem.TaxComponentAmount[index].TaxAmount))
                })
            }
        }
        billQty = this.roundUpTo2(Number(billQty) + Number(lineItem.Qty))
        billSubTotal = this.roundUpTo2(Number(billSubTotal) + Number(lineItem.SubTotal))
        billAmount = this.roundUpTo2(Number(billAmount) + Number(lineItem.Amount))
        billTaxAmount = this.roundUpTo2(Number(billTaxAmount) + Number(lineItem.TotalTax))
        billDiscount = this.roundUpTo2(Number(billDiscount) + Number(lineItem.DiscountAmount))
    })
    roundOff =  this.roundUpTo2(Math.round(billAmount) - billAmount)
    return {
      billQty: billQty,
      billSubTotal: billSubTotal,
      billAmount: billAmount,
      billTaxAmount: billTaxAmount,
      billDiscount: billDiscount,
      taxSummaryList:taxSummaryList,
      roundOff:roundOff
    }
  }

  render() {
    const {lineItems, removeProduct, changeProductQuantity,cartIsOpen,lineItemTotal  } = this.props;

    const products = lineItems.map(p => {
      return (
        <CartProduct lineItem={p} removeProduct={removeProduct} changeProductQuantity={changeProductQuantity} key={p.Product.key} />
      );
    });

    // let classes = ['float-cart'];

    // if (!!this.state.isOpen) {
    //   classes.push('float-cart--open');
    // }

    return (
      <div className={cartIsOpen ?'float-cart open-cart' : 'float-cart'}>
        {/* If cart open, show close (x) button */}
        {/* {cartIsOpen && ( */}
        <div>
          <div onClick={() => this.closeCart()} className="float-cart__close-btn">X</div>
          <div className="float-cart__header">
            <span className="bag">
              <span className="bag__quantity">{lineItemTotal.billQty}</span>
            </span>
            <span className="header-title">Cart</span>
          </div>
        </div>
        {/* )} */}

        {/* If cart is closed, show bag with quantity of product and open cart action */}
        {/* {!this.state.isOpen && (
          <span
            onClick={() => this.openFloatCart()}
            className="bag bag--float-cart-closed"
          >
            <span className="bag__quantity">{cartTotal.productQuantity}</span>
          </span>
        )} */}

        <div className="float-cart__content">
          <div className="float-cart__shelf-container">
            {products}
            {!products.length && (
              <p className="shelf-empty">
                Add some products in the cart <br />
                :)
              </p>
            )}
            <br/>
            <br/>
            <br/>
          </div>

          <div className="float-cart__footer">
            <div className="sub">SUBTOTAL</div>
            <div className="sub-price">
              <p className="sub-price__val">
                {lineItemTotal.billAmount}
              </p>
            </div>
            <div onClick={() => this.proceedToCheckout()} className="buy-btn">
              Checkout
            </div>
          </div>    
        </div>
      </div>
    );
  }

}

const mapStateToProps = state => ({
  lineItems:state.cart.lineItems,
  lineItemTotal:state.cart.lineItemTotal,
  productTaxes: state.api.productTaxes,
  cartIsOpen: state.cart.cartIsOpen,
  config: state.api.config,
  newProduct: state.cart.productToAdd,
  productToRemove: state.cart.productToRemove,
  productToChange: state.cart.productToChange
});

export default  withRouter( connect(
  mapStateToProps,
  { loadCart, loadCartTotal, removeProduct, changeProductQuantity, showCart }
)(Cart));
