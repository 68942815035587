
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchMenu } from '../../services/api/actions';
import { fetchProducts } from '../../services/util';
import Spinner from '../Spinner';
import ProductList from '../ProductList';
import PageFooter from '../PageFooter';

class Level2 extends Component {

    static propTypes = {
        menu: PropTypes.array.isRequired,
        config: PropTypes.object.isRequired,
        fetchMenu: PropTypes.func.isRequired
    };

    state = {
        products: [],
        parentID: '',
        childID: '',
        isLoading: true
    }

    async componentDidMount() {
       
    // console.log("componentDidMount", this.props.match.params.name.toLowerCase());

        let searchResult = this.props.menu.filter(f => f.Name.toLowerCase() === this.props.match.params.name.toLowerCase() && f.ParentID !=="")
        let parentID, childID = ''
        if (searchResult.length > 0) {
            childID = searchResult[0].ID
            parentID = searchResult[0].ParentID
                this.setState({ parentID: parentID, childID: childID })
        }
        //fetch products
        let products = []
        console.log('parentID',parentID)
        console.log('childID',childID)
        products = await fetchProducts(0, 50, parentID, childID)
        //console.log('after fetchproducts',products)
        this.setState({ products: products, isLoading: false })
    }


    async componentWillReceiveProps(nextProps) {

    //     const { filters: nextFilters, sort: nextSort } = nextProps;
    // const { filters } = this.props;
    // if (nextFilters.length !== filters.length) {
    //   this.handleFetchProducts(nextFilters, undefined);
    // }

    // if (nextSort !== this.props.sort) {
    //   this.handleFetchProducts(undefined, nextSort);
    // }

    // console.log(this.props.match.params.name.toLowerCase());
    // console.log('next props',nextProps.match.params.name.toLowerCase());

        let searchResult = this.props.menu.filter(f => f.Name.toLowerCase() === nextProps.match.params.name.toLowerCase() && f.ParentID !=="")
        let parentID, childID = ''
        if (searchResult.length > 0) {
            childID = searchResult[0].ID
            parentID = searchResult[0].ParentID
                this.setState({ parentID: parentID, childID: childID })
        }
        //fetch products
        let products = []
        // console.log('befoer fetchproducts',products)
        products = await fetchProducts(0, 50, parentID, childID)
        // console.log('after fetchproducts',products)
        this.setState({ products: products, isLoading: false })
    }

    render() {
        const { parentID } = this.state;
        const { childID } = this.state;
        const { isLoading } = this.state;
        const { products } = this.state;

        return <React.Fragment>
            {isLoading && <Spinner />}
            {!isLoading && <React.Fragment>
                <div className="container">
                    <div className="section-title">{this.props.match.params.name}</div>
                    <br />
                    <br />
                    {(parentID !== "" && childID !== "") && <ProductList products={products} />}
                </div>
                <br />
                <PageFooter config={this.props.config} />
            </React.Fragment>}
        </React.Fragment>
    };
}


const mapStateToProps = state => ({
    menu: state.api.menu,
    config: state.api.config
});

export default connect(
    mapStateToProps,
    { fetchMenu }
)(Level2);
