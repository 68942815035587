import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MenuThumbNails from '../MenuThumbNails';
import { fetchLatestProducts } from '../../services/util';
import { fetchLatestCarousels } from '../../services/util';
import ProducList from '../ProductList';
import PageFooter from '../PageFooter';

class Home extends Component {

  static propTypes = {
    config: PropTypes.object,
    menu: PropTypes.array
  };

  state = {
    isLoading: false,
    carousels: [],
    latestProducts: []
  }

  async componentDidMount() {
    // console.log(this.props.config)
    let latestProducts = await fetchLatestProducts(this.props.config.key, 0, 8)

    let carousels = await fetchLatestCarousels(this.props.config.key);

    // console.log('carousels', carousels);

    this.setState({ latestProducts: latestProducts, carousels: carousels })
  }

  render() {
    return <React.Fragment>
      <div id="myCarousel" className="carousel slide" data-ride="carousel" style={{ top: "60px" }}>
        <ol className="carousel-indicators">
          {this.state.carousels.map((m, index) =>
            <li data-target="#myCarousel" data-slide-to={index} className={index === 0 ? "active" : ""}></li>
          )}
        </ol>
        <div className="carousel-inner">
          {this.state.carousels.map((m, index) =>
            <div className={index === 0 ? "carousel-item active" : "carousel-item"}>
              <img src={m.Image.Preview} width="100%" height="auto" />
              <div className="container">
                <div className="carousel-caption text-left">
                  <h1>{m.Title}</h1>
                  <p>{m.SubTitle}</p>
                </div>
              </div>
            </div>
          )}
        </div>
        <a className="carousel-control-prev" href="#myCarousel" role="button" data-slide="prev">
          <span className="carousel-control-prev-icon" aria-hidden="true"></span>
          <span className="sr-only">Previous</span>
        </a>
        <a className="carousel-control-next" href="#myCarousel" role="button" data-slide="next">
          <span className="carousel-control-next-icon" aria-hidden="true"></span>
          <span className="sr-only">Next</span>
        </a>
      </div>
      <div className="container">
        <div className="section-title">Shop by category</div>
        <MenuThumbNails menu={this.props.menu.filter(f => f.ParentID === "")} businessUniqueName={this.props.config.BusinessUniqueName} />
        <div className="section-title">Top sellers</div>
        <div className="section-title">Latest collection</div>
        <ProducList products={this.state.latestProducts} />
      </div>
      <br />
      <PageFooter config={this.props.config} />
    </React.Fragment>
  }
}

const mapStateToProps = state => ({
  menu: state.api.menu,
  config: state.api.config
});

export default connect(
  mapStateToProps,
  {}
)(Home);

